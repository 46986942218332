import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function IsoRegistration() {
  /* Slider */
  // const isoSlider = [
  //   {
  //     content_color: false,
  //     header_color: false,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `ISO Registration`,
  //     content: `Apply for ISO Registration Registration with us through Online`,
  //     image: "/imgs/registration/iso/iso-reg-slider.png",
  //     alt_tag: "Best Online ISO Registration Service in Hosur",
  //   },
  //   {
  //     content_color: false,
  //     header_color: false,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online ISO Registration Service`,
  //     content: `Fast And Secure ISO registration service`,
  //     image: "/imgs/registration/iso/iso-doc.png",
  //     alt_tag: "Best Online ISO Registration near Bangalore",
  //   },
  //   {
  //     content_color: false,
  //     header_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `ISO Registration`,
  //     content: `Best Online service in Hosur`,
  //     image: "/imgs/registration/iso/iso-slider.png",
  //     alt_tag: "Best Online ISO Registration in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `ISO Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    f_price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data With Form*/
  const IsoAboutData = {
    header: `ISO Registration Online`,
    sub_title: `FOR PERSONAL AND BUSINESS RELATED ISO REGISTRATION.`,
    content_paragraph: [
      `Being legally certified can be a helpful tool to increase credibility and believability by demonstrating
      that your company, products, or services satisfy the needs of the target market.One such registration method
       that aims to raise the calibre and level of an entity's commercial endeavours,
       services, or goods is ISO certification Online India.`,

      // `Numerous industries, ranging from social responsibility and energy management to medical equipment and business
      //  risk management, are awarded ISO certification. Each ISO certification is categorised numerically
      // and holds a unique set of standards and requirements. like ISO 9001:2015`,
    ],
  };

  /* Scroll Nav Data */
  const IsoScrollId = [
    {
      id: `#procedure`,
      heading: `Registration Procedure`,
    },
    {
      id: `#certificates`,
      heading: `ISO Certificates`,
    },
    {
      id: `#documents`,
      heading: `Documents Required`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
  ];

  /* Img Content Component Data */
  const IsoIcData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Registration Procedure To Obtain ISO In India',
    image: '/imgs/registration/iso/iso-reg.png',
    alt_tag: 'ISO Registration Procedure',
    points: [
      {
        content: `As instructed by the ISO recognized Certification authority, the applicant or the organization must
         submit an application. Additionally, it must outline the company's duties and rights.`,
        icon: true,
      },
      {
        content: `The authorized registrar will now examine the company's quality manuals and all other documentation,
        including its policies and processes.The Registrar will then carry out an on-site assessment to determine what
        modifications to the firm are required.`,
        icon: true,
      },
      {
        content: `If the registrar notices anything that is not complying with the requirements for an ISO registered company,
        it is the owner's duty to develop and implement a plan to fix all issues with the entity. The action plan must include
        a thorough and detailed list of all the tasks that must be completed.`,
        icon: true,
      },
      {
        content: ` Then comes the final audit, in this step re-audit of the affected area as the registrar cannot go forward until
        all the important non-conformities get asserted.`,
        icon: true,
      },
      {
        content: ` This stage involves getting an ISO Certificate for a msme when all conformities have been formally satisfied,
         updating the results or the summary report in the ISO reports, and issuing the ISO Certificate for a msme.`,
        icon: true,
      },
      {
        content: ` In order to check and confirm that a company is properly upholding the standards of the ISO certificate,
        surveillance audits are audits that are periodically done.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const IsoCfData = {
    id: 'certificates',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Types Of ISO Certificates',
    content: ``,
    body: [
      {
        head: 'ISO 14001- Environmental Management',
        points: `This certificate is granted for maintaining the environment in the first position and it sets out
        requirements that every business working place and office must follow to standards of the environmental management system.`,
        icon: true,
      },
      {
        head: 'ISO 9001-Quality Management',
        points: `This one establishes a specific benchmark for quality management. Even though ISO 9001 registration is not essential
        to define any standards, many businesses opt to use it as it helps to generate consumer focus through creating a marketplace for
        that particular brand.`,
        icon: true,
      },
      {
        head: 'ISO 22000- Food Safety Management',
        points: `This certificate establishes standards for preserving and identifying the risks associated with food safety standards.
        This is being used by many company, and they lay forth a specific criteria for their organizations to adhere to.`,
        icon: true,
      },
      {
        head: 'ISO 26000 – Social Responsibility',
        points: `This certificate provides clear instructions to the organization, which has a major duty to safeguard the public's future.
         Additionally, it provides businesses with guidance on how to conduct themselves in a transparent and moral manner, which contributes
         to greater health and a more secure society.`,
        icon: true,
      },
      {
        head: 'ISO 50001 – Energy Management',
        points: ` In order to increase the standards of environmental management and quality, an organisation that is willing to follow this
        standard must coordinate key management with those efforts.`,
        icon: true,
      },
      {
        head: 'ISO 13485 Medical Devices',
        points: ` Companies who design, install, produce, or offer services related to medical devices should receive this certification.
        This certification serves as proof that the firms are using the highest quality control procedures and management system available.`,
        icon: true,
      },
      {
        head: 'ISO 31000 – Risk Management',
        points: `This certification offers guidelines, a framework, criteria, and a process for dealing with the risk. It's crucial to effectively
         manage the risk, which is accomplished by receiving this certificate. And to work decisively and in accordance with the expert report, which
          encourages associations to run effectively and with complete assurance.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const ImportExportImages = [
    '/imgs/registration/iso/iso-img.png',
    '/imgs/registration/iso/iso-img-slide.png',
    '/imgs/registration/iso/iso-img-slider.png',
  ];

  /*  Slider Content Component Data */
  const IsoSCData = {
    id: 'documents',
    background: [],
    mt_div: 'mt-3',
    header: 'Documents Required For Online ISO Registration In India',
    content: [
      {
        points: `Passport size photographs.`,
        icon: true,
      },
      {
        points: `Copy of PAN Card.`,
        icon: true,
      },
      {
        points: `Copy of Adhaar card or Voter ID.`,
        icon: true,
      },
      {
        points: `Sale Purchase Invoice copies.`,
        icon: true,
      },
    ],
    images: ImportExportImages,
  };
  /*  Content Img Component Data */
  const IsoCiData = {
    id: 'benefits',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Benefits Of ISO Registration Online In India',
    paragraph: ``,
    points: [
      {
        content: `If a corporation or other body wants to grow its business abroad, ISO accreditation is essential to establishing
         credibility in the global market.`,
        icon: true,
      },
      {
        content: `increases consumer happiness since ISO standards force businesses to provide the highest calibre goods and services
        to their clients.`,
        icon: true,
      },
      {
        content: `After receiving ISOcertification, the product must adhere to worldwide quality and standard requirements;
         otherwise, it will be rejected owing to quality problems.After receiving ISOcertification, the product must adhere to worldwide
         quality and standard requirements; otherwise, it will be rejected owing to quality problems.`,
        icon: true,
      },
      {
        content: `Even for official purposes like participating in government tenders, ISO certificate registration is necessary.`,
        icon: true,
      },
      {
        content: `Maintaining standards and quality in food goods helps SMMs develop, save money, and become more profitable.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/iec/iec-benefits.png',
    alt_tag: 'Benefits for ISO Registration',
  };

  // FAQ DATA
  var ISO_FAQ = [
    {
      header: 'What does ISO 9001 mean?',
      body: [
        {
          content: `An international standard body called ISO 9001 lays out the
          requirements for various entities' quality management systems (QMS).`,
          icon: false,
        },
      ],
    },
    {
      header: 'How long is an ISO Certificate valid for?',
      body: [
        {
          content: `ISO Certificates are generally valid for three years. The Certification Body will
           complete an initial Certification Audit for the first year of certification, and then undertake
           a Surveillance Audit for the second and third year. Certificates can be revoked by the Certification
           Body if a number of Nonconformities are raised at an Audit.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is ISO 9001 a legal requirement?',
      body: [
        {
          content: `No ISO 9001 is not a legal requirement. Companies can choose whether or not to be certified to
          ISO 9001 or not. That said, Government and Private Request for Tenders (RFTs) often include criteria related
          to Quality, Safety, and Environmental Certification. Depending on whether you have a Quality Management System
           that has been certified to ISO 9001 by an Accredited Third-Party Certification Body may mean the difference
           between winning the tender and going to contract, or losing the tender to a competitor. See HERE for more
           information.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Why is ISO 9001 Mandatory?',
      body: [
        {
          content: `ISO 9001 is not mandatory to have, but it may be a client-imposed mandatory requirement.
          For example, you may need to have ISO 9001 certification to be eligible for tenders and work. See HERE for more information.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'Can I choose any type of ISO accreditation?',
      body: [
        {
          content: `No, you must select the appropriate kind of certification based on
          the nature of your business operations.`,
          icon: true,
        },
      ],
    },
    {
      header: 'What is an ISO Consultant?',
      body: [
        {
          content: `ISO Consultants are engaged by companies to facilitate the development, implementation and
          external audit preparation prior to ISO Certification. The ISO Consultant is a role that is dedicated
          to helping organisations to build practical, efficient management systems that add value to the
          organisation and guarantee certification to the relevant ISO Standards.`,
          icon: true,
        },
      ],
    },
    {
      header: 'Are there Government Grants or Funding for ISO Certification?',
      body: [
        {
          content: `Many Australian businesses are actually eligible for help with ISO 9001 Certification through
          a government-funded Business Growth Grant? Click HERE for more information.`,
          icon: true,
        },
      ],
    },
    {
      header: 'What is an ISO Certification?',
      body: [
        {
          content: `An organization can receive approval to use an ISO management system
          by receiving an ISO certification, which is a document given by an
          independent third party.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'How much does the ISO registration process cost?',
      body: [
        {
          content: `Depending on the size of the business and the stage/level of the
          organization it is operating at, ISO registration might be
          expensive.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'How much time is needed to obtain ISO registration?',
      body: [
        {
          content: `  The length of time required for the ISO registration process varies
          depending on the size of the organization such as,`,
          icon: false,
        },
        {
          content: `Large business:
          12-15 months`,
          icon: true,
        },
        {
          content: `Medium business:
          8-12 months`,
          icon: true,
        },
        {
          content: `Small business:
          6-8 months`,
          icon: true,
        },
      ],
    },
  ];

  return (
    <div>
      <Seo
        title='ISO Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={isoSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={IsoAboutData} />

          <Counter />

          <ScrollNav scroll_data={IsoScrollId} />

          <ImgContent item={IsoIcData} />

          <ContentForm CFSection_data={IsoCfData} />

          <SliderContent ScSection_data={IsoSCData} />

          <ContentImg CISection_data={IsoCiData} />

          <Cta />
          <Guidance />

          <FAQAccord items={ISO_FAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
